<template>
    <div>
        <statistics-bar-chart-template
            scale="daily"
            type="subscriptions"
            :show-labels="false"
            :show-tooltips="true"
            :show-legend="true"
            :deep-values="true"
            :hidden-keys="['trial']"
        >
            <template #title>{{ $t('subscriptions.daily') }}</template>
        </statistics-bar-chart-template>

        <statistics-bar-chart-template
            scale="weekly"
            type="subscriptions"
            :show-labels="false"
            :show-tooltips="true"
            :show-legend="true"
            :deep-values="true"
            :hidden-keys="['trial']"
        >
            <template #title>{{ $t('subscriptions.weekly') }}</template>
        </statistics-bar-chart-template>

        <statistics-bar-chart-template
            scale="monthly"
            type="subscriptions"
            :show-labels="false"
            :show-tooltips="true"
            :show-legend="true"
            :deep-values="true"
            :hidden-keys="['trial']"
        >
            <template #title>{{ $t('subscriptions.monthly') }}</template>
        </statistics-bar-chart-template>
    </div>
</template>

<script>
import StatisticsBarChartTemplate from '../statistics-components/statistics-dynamic/StatisticsBarChartTemplate.vue';

export default {
    components: { StatisticsBarChartTemplate },
};
</script>

<style lang="scss" scoped></style>
